import {gql} from "graphql-tag";
import {HOME_SCREEN_FIELDS} from "./Fragments";

export const GET_HOME_SCREENS = gql`
    query GetHomeScreens {
            home_screens {
                    ...HomeScreenFields
            }
    }
    ${HOME_SCREEN_FIELDS}
`;

export const SEARCH_HOME_SCREENS = gql`
    query SearchHomeScreens($filter:String, $search_key:String, $locale:String, $type:String , $date_from:String , $date_to:String) {
            search_home_screens(filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
                    ...HomeScreenFields
            }
    }
    ${HOME_SCREEN_FIELDS}
`;

export default {GET_HOME_SCREENS, SEARCH_HOME_SCREENS};
