import { gql } from "graphql-tag";
import { HOME_SCREEN_FIELDS } from "./Fragments";

export const CREATE_HOME_SCREEN = gql`
	mutation CreateHomeScreen($input: HomeScreenInput!) {
		create_home_screen(home_screen: $input) {
			...HomeScreenFields
		}
	}
	${HOME_SCREEN_FIELDS}
`;

export const UPDATE_HOME_SCREEN = gql`
	mutation UpdateHomeScreen($input: HomeScreenInput!) {
		update_home_screen(home_screen: $input) {
			...HomeScreenFields
		}
	}
	${HOME_SCREEN_FIELDS}
`;

export const UPDATE_SCREEN_POSITION = gql`
	mutation UpdateScreenPosition($input: UpdateScreenPositionInput!) {
		update_screen_position(screen_position: $input)
	}
`;

export const DELETE_HOME_SCREEN = gql`
	mutation DeleteHomeScreen($id: Int!) {
		delete_home_screen(id: $id)
	}
`;

export default { CREATE_HOME_SCREEN, UPDATE_HOME_SCREEN, UPDATE_SCREEN_POSITION, DELETE_HOME_SCREEN };
