
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import HomeScreensList from "../components/HomeScreensList.vue";
import AddHomeScreen from "../components/AddHomeScreen.vue";
import EditHomeScreen from "../components/EditHomeScreen.vue";

export default defineComponent({
	name: "home-screens",
	components: {
        HomeScreensList,
		AddHomeScreen,
        EditHomeScreen
	},
	setup() {
		onMounted(() => {
            setPageHeader({
                title: "message.HOME_SCREEN message.LIST",
                actionButton: {
                    ability:'add_home_screen',
                    pageAction: {
                        action: "addHomeScreenAction",
                    },
                    button: {
                        title: `message.ADD message.HOME_SCREEN`,
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.HOME_SCREEN message.LIST"}
                ]
            });
		});
	},
});
