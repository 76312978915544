
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, watchEffect } from "vue";
import { Input, Image } from "@/components/input-elements";
import { useStore } from "vuex";
import { HomeScreenEditFormData } from "../interfaces/index";
import { Apollo, Notify } from "@/core/services";
import { GET_HOME_SCREENS } from "../graphql/Queries";
import { UPDATE_HOME_SCREEN } from "../graphql/Mutations";
import { Actions } from "@/store/enums/StoreEnums";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "new-category-modal",
	components: {
		Input,
		Image,
		InnerLoader,
	},
	setup() {
		const emitter: any = inject("emitter");
		const loading = ref(false);
		const locales = ref([]) as Record<any, any>;
		const modal: any = ref();
		const tabsValue = ref("da");
		const tempValuesLocale = ref({}) as Record<any, any>;
		const tempValuesLabel = ref({}) as Record<any, any>;
		const tempValuesTitle = ref({}) as Record<any, any>;
		const tempValuesDescription = ref({}) as Record<any, any>;
		const tempValuesButtonText = ref({}) as Record<any, any>;
		const store = useStore();
		const disabled = ref(false);
		const set_sites = ref([]) as Record<any, any>;
		const editHomeScreenForm = ref<null | HTMLFormElement>(null);
		const categories = ref([]) as Record<any, any>;
		const previewImage = ref([]) as Record<any, any>;
		const i18n = useI18n();

		const HomeScreenData = ref<HomeScreenEditFormData>({
			id: 0,
			locale: "da",
			label: "",
			title: "",
			description: "",
			button_link: "",
			button_text: "",
			status: false,
		});

		const categoriesList = ref([]);

		watchEffect(() => {
			categoriesList.value.forEach((element: Record<any, any>) => {
				let defaultLocale = "";
				if (JSON.parse(element.name)[`${HomeScreenData.value.locale}`] == undefined) {
					defaultLocale = "da";
				} else {
					defaultLocale = HomeScreenData.value.locale;
				}
				categories.value.push({
					value: element.id,
					label: JSON.parse(element.name)[`${defaultLocale}`].name,
				});
			});
		});

		// On change image
		const handleChange = async (event: Record<any, any>) => {
			loading.value = true;
			const media = event.target.files;
			const response = await store.dispatch(Actions.CREATE_MEDIA, media);
			const images = JSON.parse(response);
			images.forEach((element) => {
				previewImage.value.push({ src: element.asset_path, id: element.id });
			});
			loading.value = false;
		};

		// Remove Media Function
		const removeMedia = (index: number, media_id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
					if (resp == "true") {
						previewImage.value.splice(index, 1);
						Notify.success(i18n.t("message.MEDIA_REMOVED_SUCCESSFULLY"));
					}
					loading.value = false;
				})
				.catch(() => {
					loading.value = false;
				});
		};

		// Show Media
		const showMedia = async (num: number) => {
			const formData = {
				previewImage: previewImage.value,
				visible: true,
				index: num,
			};
			await store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: "imageProps" });
		};

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});
				tempValuesLocale.value[element.locale] = element.locale;
				tempValuesLabel.value[element.locale] = { name: "" };
				tempValuesTitle.value[element.locale] = { name: "" };
				tempValuesDescription.value[element.locale] = { name: "" };
				tempValuesButtonText.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			tabsValue.value = e;
			HomeScreenData.value.label = tempValuesLabel.value[e].name;
			HomeScreenData.value.title = tempValuesTitle.value[e].name;
			HomeScreenData.value.description = tempValuesDescription.value[e].name;
			HomeScreenData.value.button_text = tempValuesButtonText.value[e].name;
		};

		const updateLabelValue = (e: string) => {
			tempValuesLabel.value[HomeScreenData.value.locale].name = e;
		};

		const updateTitleValue = (e: string) => {
			tempValuesTitle.value[HomeScreenData.value.locale].name = e;
		};

		const updateButtonTextValue = (e: string) => {
			tempValuesButtonText.value[HomeScreenData.value.locale].name = e;
		};

		// tab link press to ge locale
		const tabClickHandler = (tab: Record<any, any>) => {
			// HomeScreenData.value.description = tempValuesDescription.value[tab.props.name].name;
			// HomeScreenData.value.locale = tab.props.name;

			HomeScreenData.value.locale = tab.props.name;
			HomeScreenData.value.label = tempValuesLabel.value[tab.props.name].name;
			HomeScreenData.value.title = tempValuesTitle.value[tab.props.name].name;
			HomeScreenData.value.description = tempValuesDescription.value[tab.props.name].name;
			HomeScreenData.value.button_text = tempValuesButtonText.value[tab.props.name].name;
		};

		// Update value on change description
		const updateValueDescription = (e: string) => {
			tempValuesDescription.value[HomeScreenData.value.locale].name = e;
		};

		// Form submit Handler
		const submitHomeScreenForm = () => {
			for (const i in tempValuesLocale.value) {
				if (tempValuesDescription.value[i].name == "") {
					tempValuesDescription.value[i].name = HomeScreenData.value.description;
				}

				if (tempValuesLabel.value[i].name == "") {
					tempValuesLabel.value[i].name = HomeScreenData.value.label;
				}

				if (tempValuesTitle.value[i].name == "") {
					tempValuesTitle.value[i].name = HomeScreenData.value.title;
				}

				if (tempValuesButtonText.value[i].name == "") {
					tempValuesButtonText.value[i].name = HomeScreenData.value.button_text;
				}
			}

			editHomeScreenForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						id: HomeScreenData.value.id,
						label: JSON.stringify(tempValuesLabel.value),
						title: JSON.stringify(tempValuesTitle.value),
						description: JSON.stringify(tempValuesDescription.value),
						button_link: HomeScreenData.value.button_link,
						button_text: JSON.stringify(tempValuesButtonText.value),
						status: HomeScreenData.value.status,
						image: previewImage.value.length != 0 ? previewImage.value[0].id : null,
					};
					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_HOME_SCREEN,
						variables: {
							input: formData,
						},
						update: (store, { data: { update_home_screen } }) => {
							const previous_records = store.readQuery({
								query: GET_HOME_SCREENS,
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_HOME_SCREENS,
								data: {
									home_screens: [...previous_records.home_screens],
								},
								variables: {
									id: update_home_screen.id,
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		const resetForm = () => {
			previewImage.value = [];
			emitter.emit("clearInput");
			tabsValue.value = "da";
			editHomeScreenForm.value?.resetFields();
			for (const i of Object.getOwnPropertyNames(tempValuesLabel.value)) {
				tempValuesLabel.value[i].name = "";
				tempValuesTitle.value[i].name = "";
				tempValuesButtonText.value[i].name = "";
				tempValuesDescription.value[i].name = "";
			}
		};

		// Emitter for open modal
		emitter.on("editHomeScreenAction", (home_screen: Record<any, any>) => {
			resetForm();
			HomeScreenData.value.id = home_screen.id;
			HomeScreenData.value.button_link = home_screen.button_link;
			HomeScreenData.value.status = home_screen.status;

			const json_decode_label = JSON.parse(home_screen.label);
			const json_decode_title = JSON.parse(home_screen.title);
			const json_decode_description = JSON.parse(home_screen.description);
			const json_decode_button_text = JSON.parse(home_screen.button_text);

			for (const i in tempValuesLocale.value) {
				// label
				i == "da" ? (HomeScreenData.value.label = json_decode_label[i].name) : "";
				tempValuesLabel.value[i].name = json_decode_label[i] != undefined ? json_decode_label[i].name : "";

				// title
				i == "da" ? (HomeScreenData.value.title = json_decode_title[i].name) : "";
				tempValuesTitle.value[i].name = json_decode_title[i] != undefined ? json_decode_title[i].name : "";

				// description
				i == "da" ? (HomeScreenData.value.description = json_decode_description[i].name) : "";
				tempValuesDescription.value[i].name = json_decode_description[i] != undefined ? json_decode_description[i].name : "";

				// button_text
				i == "da" ? (HomeScreenData.value.button_text = json_decode_button_text[i].name) : "";
				tempValuesButtonText.value[i].name = json_decode_button_text[i] != undefined ? json_decode_button_text[i].name : "";
			}
			if (home_screen.image) {
				previewImage.value.push({
					id: home_screen.image.id,
					src: home_screen.image.file_path,
				});
			}
			modal.value = new Modal(document.getElementById("modal_edit_home_screen"));
			modal.value.show();
		});

		return {
			set_sites,
			locales,
			HomeScreenData,
			loading,
			tabsValue,
			disabled,
			categories,
			editHomeScreenForm,
			previewImage,
			showMedia,
			removeMedia,
			handleChange,
			tabClickHandler,
			updateValueDescription,
			changeLang,
			updateLabelValue,
			updateTitleValue,
			updateButtonTextValue,
			submitHomeScreenForm,
		};
	},
});
