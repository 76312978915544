import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeScreensList = _resolveComponent("HomeScreensList")!
  const _component_AddHomeScreen = _resolveComponent("AddHomeScreen")!
  const _component_EditHomeScreen = _resolveComponent("EditHomeScreen")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_HomeScreensList)
    ]),
    _createVNode(_component_AddHomeScreen),
    _createVNode(_component_EditHomeScreen)
  ], 64))
}